<template>
  <div>
    <v-dialog
      v-if="payment"
      v-model="thedialog"
      max-width="700"
      @click:outside="closeDialog"
    >
      <!-- <template #activator="{ on, attrs }"> -->
      <!-- </template> -->

      <v-card>
        <v-card-title>
          Paymet by {{ concatFirstLastName(payment.order.customer.firstName, payment.order.customer.lastName) }}
        </v-card-title>
        <v-card-subtitle>
          Payment ID {{ payment.uuid | uuidLast8 }}
        </v-card-subtitle>
        <!-- <v-toolbar flat>
            <span class="title">Order by {{ order.id | uuidLast8 }}</span>
          </v-toolbar> -->
        <v-card-text>
          <v-divider />
          <div class="text-h2 orange--text">
            TO DO ONCE THERE'RE PAYMENTS
          </div>
          <v-divider />

          <!-- Customer INFO -->
          <v-container mt-4>
            <div class="body-2 font-weight-bold pb-5">
              Customer information
            </div>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="payment.order.customer.firstName"
                  label="First name"
                  outlined
                  readonly
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="payment.order.customer.firstName"
                  label="Last name"
                  outlined
                  readonly
                  dense
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="6">
                <v-text-field
                  :value="payment.order.customer.email"
                  label="Email"
                  outlined
                  readonly
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="payment.order.customer.phone"
                  label="Phone"
                  outlined
                  readonly
                  dense
                />
              </v-col>
            </v-row>

            <div class="body-2 font-weight-bold mt-4 pb-5">
              Order information
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions class="justify-center pb-4">
          <v-btn
            outlined
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mixins from '../../helpers/mixins';

export default {
  mixins: [mixins],
  // props: {
  //   payment: {
  //     type: Object,
  //     default: () => {},
  //   },
  // },
  computed: {
    payment() {
      return this.$store.getters['payments/getPaymentDialogObject'];
    },
    thedialog: {
      get() {
        return this.$store.getters['payments/getPaymentDialogValue'];
      },
      set(value) {
        this.$store.commit('payments/SET_PAYMENT_DIALOG_VALUE', value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('payments/SET_PAYMENT_DIALOG_VALUE', false);
      this.$store.commit('payments/SET_PAYMENT_DIALOG_OBJECT', null);
    },
  },
};
</script>
