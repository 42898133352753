var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PaymentDialog'),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('h1',[_vm._v("Payments")])])],1),(_vm.payments)?_c('div',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.payments,"search":_vm.search},on:{"click:row":_vm.paymentDialog},scopedSlots:_vm._u([{key:"item.paymentId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uuidLast8")(item.paymentId))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getPaymentsStatusColor(item.status),"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.concatFirstLastName(item.order.customer.firstName, item.order.customer.lastName))+" ")]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatDataTables(item.dateCreated))+" ")]}},{key:"item.paymentTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberToMoney(item.paymentTotal))+" ")]}}],null,true)})],1)],1):_c('div',[_vm._v(" LOADING... ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }