/* eslint-disable no-param-reassign */
import axios from 'axios';
import router from '../router/index';
import { setProp, getProp } from '../helpers/vuexHelpers';

const publicOrderForm = {
  namespaced: true,

  state: {
    order: {},
  },
  getters: {
    get: (state) => (props) => getProp(state, props),
  },
  mutations: {
    SET(state, payload) {
      setProp(state, [...payload[0]], payload[1]);
    },
  },
  actions: {
    async setOrder({ commit }) {
      const endpoint = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/order/${router.currentRoute.params.uuid}`;

      const data = await axios.get(endpoint);
      commit('SET', [['order'], data.data]);
    },
  },
};

export default publicOrderForm;
