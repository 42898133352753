<template>
  <v-main
    class="grey lighten-5"
  >
    <v-container
      fill-height
      fluid
    >
      <v-row
        justify="center"
      >
        <v-col
          md="7"
          lg="5"
          sm="10"
        >
          <v-card
            elevation="1"
          >
            <!-- height="calc(100vh - 100px)" -->

            <v-fade-transition
              mode="out-in"
              duration="200"
            >
              <slot />
            </v-fade-transition>
          </v-card>
        </v-col>

        <v-col
          md="4"

          class="d-none d-md-block"
        >
          <v-container
            fill-height
          >
            <!-- style="position: sticky; top: 76px" -->
            <div>
              <v-card color="transparent">
                <v-img
                  max-width="450"
                  src="@/assets/img/undraw_relaxation_1_wbr7.svg"
                />
              </v-card>
            </div>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style scoped>
img {
    width:100%;
    height:100%;
    }
</style>
