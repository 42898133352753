import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import SelectProperty from '../views/SelectProperty.vue';
import Home from '../views/Home.vue';
import Orders from '../views/Orders.vue';
import Payments from '../views/Payments.vue';
import Customers from '../views/Customers.vue';
import Notes from '../views/Notes.vue';
import Reports from '../views/Reports.vue';
import Settings from '../views/Settings.vue';
import Profile from '../views/Profile.vue';
import PublicOrderForm from '../views/PublicOrderForm.vue';
import PublicOrderFormResult from '../views/PublicOrderFormResult.vue';
import NotFound from '../views/NotFound.vue';
import { projectAuth } from '../firebase/config';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      layout: 'StartLayout',
    },
  },
  {
    path: '/select',
    name: 'SelectProperty',
    component: SelectProperty,
    meta: {
      requiresAuth: true,
      layout: 'StartLayout',
    },
  },
  {
    path: '/', redirect: { name: 'SelectProperty' },
  },
  {
    path: '/:slug/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout', // This is the deufalt layout (set in App.vue) and not needed to set but prefer to be explicit.
    },
  },
  {
    path: '/:slug/orders',
    name: 'Orders',
    component: Orders,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout',
    },
  },
  {
    path: '/:slug/payments',
    name: 'Payments',
    component: Payments,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout',
    },
  },
  {
    path: '/:slug/customers',
    name: 'Customers',
    component: Customers,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout',
    },
  },
  {
    path: '/:slug/notes',
    name: 'Notes',
    component: Notes,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout',
    },
  },
  {
    path: '/:slug/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout',
    },
  },
  {
    path: '/:slug/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout',
    },
  },
  {
    path: '/:slug/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true,
      layout: 'AppLayout',
    },
  },
  {
    path: '/order/:uuid',
    name: 'PublicOrderForm',
    component: PublicOrderForm,
    meta: {
      requiresAuth: false,
      layout: 'PublicOrderFormLayout',
    },
  },
  {
    path: '/order/:uuid/result',
    name: 'PublicOrderFormResult',
    component: PublicOrderFormResult,
    meta: {
      requiresAuth: false,
      layout: 'PublicOrderFormLayout',
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'catchAll',
    component: NotFound,
    meta: {
      requiresAuth: false,
      layout: 'StartLayout',
    },
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !projectAuth.currentUser) {
    next('/login');
  } else {
    next();
  }
});

export default router;
