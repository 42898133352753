import axios from 'axios';
import router from '../router/index';
// import { getHotelIdFromSlug } from '../helpers/helpers';
import { projectAuth } from '../firebase/config';

const orders = {
  namespaced: true,
  state: {
    payments: null,
    paymentDialog: false,
    paymentDialogObject: null,
  },
  getters: {
    getPayments(state) {
      return state.payments;
    },
    getPaymentDialogValue(state) {
      return state.paymentDialog;
    },
    getPaymentDialogObject(state) {
      return state.paymentDialogObject;
    },
  },
  mutations: {
    SET_PAYMENTS(state, payload) {
      state.payments = payload;
    },
    SET_PAYMENT_DIALOG_VALUE(state, payload) {
      state.paymentDialog = payload;
    },
    SET_PAYMENT_DIALOG_OBJECT(state, payload) {
      state.paymentDialogObject = payload;
    },
  },
  actions: {
    async setPayments({ commit }) {
      // const authUser = rootGetters['auth/user'];
      // const hotelId = getHotelIdFromSlug(authUser, router.currentRoute.params.slug);
      // const mockDataRelationships = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/payments?hotelId=${hotelId}&_expand=order&_expand=customer`;
      // const data = await axios.get(mockDataRelationships);

      const endpoint = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/payments?organisation=${router.currentRoute.params.slug}`;
      const idToken = await projectAuth.currentUser.getIdToken();

      const data = await axios.get(endpoint, {
        headers: {
          authorization: idToken,
        },
      });

      commit('SET_PAYMENTS', data.data);
    },
  },
};

export default orders;
