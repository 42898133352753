<template>
  <div>
    Home
    m  {{ mode }}
  </div>
</template>

<script>

export default {
  name: 'Home',

  components: {
  },
  data() {
    return {
      mode: process.env.VUE_APP_RESOURCES_API_URL,
    };
  },
};
</script>
