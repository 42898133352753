<template>
  <v-container>
    <v-row>
      <v-col
        sm="4"
        class="text-center"
      >
        <div class="body-2 ">
          Status
        </div>
        <v-chip
          :color="getOrderStatusColor(order.status)"
        >
          {{ order.status }}
        </v-chip>
      </v-col>
      <v-col
        sm="4"
        class="text-center"
      >
        <div class="body-2">
          Created on
        </div>
        <div class="subtitle-1 font-weight-bold">
          {{ dateFormatDataTables(order.dateCreated) }}
        </div>
      </v-col>
      <v-col
        sm="4"
        class="text-center"
      >
        <div class="body-2">
          {{ lastColumnData.title }}
        </div>
        <div class="subtitle-1 font-weight-bold">
          {{ lastColumnData.date }}
        </div>

        <div class="subtitle-1 font-weight-bold" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from '../../helpers/mixins';
import { enumOrderStatuses } from '../../helpers/enums';

export default {
  mixins: [mixins],
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    lastColumnData() {
      console.log(this.order.status);
      switch (this.order.status) {
        case enumOrderStatuses.NEW:
          return {
            title: 'Expiration',
            date: this.dateFormatDataTables(this.order.expirationDate) || 'Open',

          };
        case enumOrderStatuses.PENDING_OPENED:
          return {
            title: 'Expiration',
            date: this.dateFormatDataTables(this.order.expirationDate) || 'Open',

          };
        case enumOrderStatuses.ACCEPTED:
          return {
            title: 'Authorized on',
            date: this.dateFormatDataTables(this.order.dateAccepted),

          };
        case enumOrderStatuses.CANCELED:
          return {
            title: 'Canceled on',
            date: this.dateFormatDataTables(this.order.dateCanceled),
          };
        case enumOrderStatuses.EXPIRED:
          return {
            title: 'Expiration',
            date: this.dateFormatDataTables(this.order.expirationDate) || 'Open',
          };
        default:
          return '';
      }
    },
  },

  //   mounted() {
  //     // console.log();
  //   },

};
</script>

<style>

</style>
