<template>
  <v-text-field
    :ref="fieldReference"
    v-model="theamount"
    prefix="€"
    :label="label"
    outlined
    dense
  />
</template>

<script>
import AutoNumeric from 'autonumeric';

export default {
  props: {
    fieldReference: {
      type: String,
      default: 'fieldRef',
    },
    label: {
      type: String,
      default: 'fieldLabel',
    },
    defAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      theamount: this.defAmount ? AutoNumeric.format(this.defAmount / 100) : null,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    theamount(v) {
      // console.log(v);
      // AutoNumeric.format returns the string representation, e.g. 12.00 or 42.15
      // Remove the dot and convert to integer, so the value corresponds to cents which is how we save in the DB
      const amount = +AutoNumeric.format(this.$refs[this.fieldReference].$refs.input).replace('.', '').replaceAll(',', '');
      this.$emit('update', amount);
      AutoNumeric.format(this.$refs[this.fieldReference].$refs.input);
      // AutoNumeric.reformatAndSet(this.$refs[this.fieldReference].$refs.input);
    },
  },
  mounted() {
    // const autoNumericOptionsEuro = {
    //     digitGroupSeparator        : '.',
    //     decimalCharacter           : ',',
    //     decimalCharacterAlternative: '.',
    //     currencySymbol             : '\u202f€',
    //     currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.suffix,
    //     roundingMethod             : AutoNumeric.options.roundingMethod.halfUpSymmetric,
    // };
    // eslint-disable-next-line no-new
    new AutoNumeric(this.$refs[this.fieldReference].$refs.input);
    if (this.defAmount > 0) {
      AutoNumeric.formatAndSet(this.$refs[this.fieldReference].$refs.input);
    }
  },
  // methods: {
  //   handleFormat() {
  //     return AutoNumeric.formatAndSet(this.theamount);
  //   },
  // },
};

</script>

<style>

</style>
