<template>
  <div>
    Notes
  </div>
</template>

<script>

export default {
  name: 'Orders',

  components: {
  },
};
</script>
