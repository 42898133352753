import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './helpers/filters';

// layouts
import StartLayout from './layouts/StartLayout.vue';
import AppLayout from './layouts/AppLayout.vue';
import PublicOrderFormLayout from './layouts/PublicOrderFormLayout.vue';

import { projectAuth } from './firebase/config';

Vue.component('StartLayout', StartLayout);
Vue.component('AppLayout', AppLayout);
Vue.component('PublicOrderFormLayout', PublicOrderFormLayout);

require('./assets/styles/global.css');

Vue.config.productionTip = false;

// Wait for firebase auth to initi before creating the app

let app = null;

projectAuth.onAuthStateChanged(async (user) => {
  // init app if not already created
  if (!app) {
    app = new Vue({
      el: '#app',
      router,
      store,
      vuetify,
      render: (h) => h(App),
    });
  }

  if (user) {
    await store.dispatch('auth/fetchAuthUserData', user);
    // app.$mount('#app');
  }
});
