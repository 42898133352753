<template>
  <div>
    <OrderDialog />
    <NewOrderDialog
      :key="newOrderKey"
      @cleared="clearedNewOrderDialog()"
    />

    <v-container>
      <v-row justify="space-between">
        <h1>Orders</h1>
        <v-btn
          color="primary"
          @click.stop="newOrderDialog"
        >
          <v-icon
            left
          >
            mdi-plus
          </v-icon>
          New Order
        </v-btn>
      </v-row>
    </v-container>
    <div v-if="orders">
      <v-card elevation="2">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          class="row-pointer"
          :headers="headers"
          :items="orders"
          :search="search"
          @click:row="orderDialog"
        >
          <template #[`item.uuid`]="{ item }">
            {{ item.uuid | uuidLast8 }}
          </template>
          <template
            #[`item.dateCreated`]="{ item }"
          >
            {{ dateFormatDataTables(item.dateCreated) }}
          </template>
          <template
            #[`item.fullName`]="{ item }"
          >
            {{ concatFirstLastName(item.customer.firstName, item.customer.lastName) }}
          </template>
          <template
            #[`item.amountToChargeInCents`]="{ item }"
          >
            {{ numberToMoney(item.amountToChargeInCents) }}
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip
              :color="getOrderStatusColor(item.status)"
              small
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.type`]="{ item }">
            {{ getBookingType(item.type) }}
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-else>
      LOADING...
    </div>
  </div>
</template>

<script>
import OrderDialog from '../components/orders/OrderDialog.vue';
import NewOrderDialog from '../components/orders/NewOrderDialog.vue';
import { enumOrderTypes } from '../helpers/enums';

import mixins from '../helpers/mixins';

export default {
  components: {
    OrderDialog,
    NewOrderDialog,
  },
  mixins: [mixins],
  data() {
    return {
      newOrderKey: Math.random(),
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'start',
          filterable: true,
          value: 'uuid',
        },
        { text: 'Customer', value: 'fullName' },
        { text: 'firstName', value: 'customer.firstName', align: ' d-none' }, // hides but keeps filterable
        { text: 'lastName', value: 'customer.lastName', align: ' d-none' },
        { text: 'Date Created', value: 'dateCreated' },
        { text: 'Status', value: 'status' },
        { text: 'Type', value: 'type' },
        { text: 'Charge', value: 'amountToChargeInCents' },
      ],
    };
  },
  computed: {
    orders() {
      return this.$store.getters['orders/getOrders'];
    },
  },
  mounted() {
    this.$store.dispatch('orders/setOrders');
  },
  methods: {
    orderDialog(item) {
      this.$store.commit('orders/SET_ORDER_DIALOG_OBJECT', item);
      this.$store.commit('orders/SET_ORDER_DIALOG_VALUE', true);
    },
    newOrderDialog() {
      this.$store.commit('newOrder/SET_NEW_ORDER_DIALOG_VALUE', true);
    },
    clearedNewOrderDialog() {
      this.newOrderKey += 1;
    },
    getBookingType(type) {
      return enumOrderTypes[type];
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
