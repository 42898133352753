<template>
  <v-app>
    <!-- Navigation drawer -->

    <!-- App Bar -->

    <!-- v-main -->

    <v-main :class="backgroundClass">
      <v-fade-transition mode="out-in">
        <component :is="layout">
          <router-view />
        </component>
      </v-fade-transition>
    </v-main>
    <!-- <v-main>
    </v-main> -->
  </v-app>
</template>

<script>
const defaultLayout = 'AppLayout';

export default {
  name: 'App',
  data() {
    return {
      drawer: true,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard' },
        { title: 'Photos', icon: 'mdi-image' },
        { title: 'About', icon: 'mdi-help-box' },
      ],
      right: null,
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout);
    },
    backgroundClass() {
      if (['AppLayout', 'PublicOrderFormLayout'].includes(this.$route.meta.layout)) {
        return 'main-background';
      }
      return 'start-background';
    },
  },
};
</script>
