<template>
  <div>
    <PaymentDialog />
    <v-container>
      <v-row justify="space-between">
        <h1>Payments</h1>
      </v-row>
    </v-container>
    <div v-if="payments">
      <v-card elevation="2">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          class="row-pointer"
          :headers="headers"
          :items="payments"
          :search="search"
          @click:row="paymentDialog"
        >
          <template #[`item.paymentId`]="{ item }">
            {{ item.paymentId | uuidLast8 }}
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip
              :color="getPaymentsStatusColor(item.status)"
              small
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template #[`item.customer`]="{ item }">
            {{ concatFirstLastName(item.order.customer.firstName, item.order.customer.lastName) }}
          </template>
          <template #[`item.dateCreated`]="{ item }">
            {{ dateFormatDataTables(item.dateCreated) }}
          </template>
          <!-- <template #[`item.paymentMethod`]="{ item }">
            {{ getCCicon(item.paymentMethod) }}
            <v-img
              v-if="item.paymentMethod != 'other'"
              width="30"
              :src="
                require(`@/assets/creditcard-icons/${item.paymentMethod}.svg`)
              "
            />
          </template> -->
          <template #[`item.paymentTotal`]="{ item }">
            {{ numberToMoney(item.paymentTotal) }}
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div v-else>
      LOADING...
    </div>
  </div>
</template>

<script>
import PaymentDialog from '../components/payments/PaymentDialog.vue';
import mixins from '../helpers/mixins';

export default {
  components: {
    PaymentDialog,
  },
  mixins: [mixins],
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'ID',
          align: 'start',
          filterable: true,
          value: 'paymentId',
        },
        { text: 'Customer', value: 'customer' },
        { text: 'Date', value: 'dateCreated' },
        // { text: 'Payment Date', value: 'paymentDate' },
        { text: 'Status', value: 'status' },
        // { text: 'Card', value: 'paymentMethod' },
        { text: 'Payment Total', value: 'paymentTotal' },
      ],
    };
  },
  computed: {
    payments() {
      return this.$store.getters['payments/getPayments'];
    },
  },
  mounted() {
    this.$store.dispatch('payments/setPayments');
  },
  methods: {
    getCCicon(paymentMethod) {
      switch (paymentMethod) {
        case 'visa':
          return '';

        default:
          return null;
      }
    },
    paymentDialog(item) {
      this.$store.commit('payments/SET_PAYMENT_DIALOG_OBJECT', item);
      this.$store.commit('payments/SET_PAYMENT_DIALOG_VALUE', true);
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
