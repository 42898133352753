<template>
  <v-container
    fill-height
    class="text-center"
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        sm="6"
        lg="4"
      >
        <slot />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
img {
    width:100%;
    height:100%;
    }
</style>
