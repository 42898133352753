import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import orders from './orders';
import newOrder from './newOrder';
import payments from './payments';
import customers from './customers';
import publicOrderForm from './publicOrderForm';
// import authHotelUser from './authHotelUser';
//
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    orders,
    newOrder,
    payments,
    publicOrderForm,
    customers,
    // authHotelUser,
  },
});
