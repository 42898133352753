<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="orderReminderDates"
          calculate-widths
          hide-default-footer
          disable-sort
        >
          <template
            #[`item.date`]="{ item }"
          >
            {{ item.date.toISOString() | IsoToBookingDates }}
          </template>
          <template
            #[`item.daystoarrival`]="{ item }"
          >
            {{ item.daysFromArrival }} days
          </template>
          <template #[`item.actions`]="{ item }">
            <v-icon
              small
              @click="removeReminder(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row
      v-if="nowTillCheckin > 0"
    >
      <v-col cols="3">
        <v-text-field
          ref="numOfDays"
          v-model="addReminderDate"
          dense
          outlined
          type="number"
          min="1"
          hint="Days before arrival"
          persistent-hint
          :error-messages="addReminderDateErrorMessages"
          :rules="[() => (addReminderDate <= nowTillCheckin && addReminderDate >0 ) || 'Invalid number of days' ]"
          :max="nowTillCheckin"
        />
      </v-col>
      <v-col>
        <v-btn
          color="primary"
          depressed
          small
          :disabled="($refs.numOfDays && $refs.numOfDays.hasError) || orderReminderDates.length >= 3"
          @click="addReminder"
        >
          add
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  format, startOfToday, parseISO, differenceInDays, subDays,
} from 'date-fns';

export default {
  props: {
    checkIn: {
      type: String,
      default: '',
    },
    orderCommunications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      expDateMenu: false,
      finalDate: null,
      addReminderDate: null,
      addReminderDateErrorMessages: '',
      // orderReminderDates: Object.keys(this.bookingTerm).length && this.checkIn ? this.bookingTermToReminderDate() : [],
      orderReminderDates: this.orderCommunications,
      headers: [
        { text: 'Date', value: 'date', width: '20%' },
        { text: 'Time to arrival', value: 'daystoarrival', width: '20%' },
        {
          text: 'Remove', value: 'actions', align: 'left', width: '0',
        },
      ],

    };
  },
  computed: {
    nowTillCheckin() {
      return differenceInDays(parseISO(this.checkIn), startOfToday()) - 1;
    },
  },
  watch: {
    // orderCommunications(val) {
    //   this.orderReminderDates = val;
    // },
  },
  methods: {
    allowedDates(val) {
      const today = startOfToday();
      const todayFormated = format(today, 'yyyy-MM-dd');
      return val >= todayFormated;
    },
    removeReminder(item) {
      this.orderReminderDates = this.orderReminderDates.filter((el) => el.date !== item.date);
      this.$emit('update', this.serialisedDates(this.orderReminderDates));
    },
    addReminder() {
      if (!this.addReminderDate) return;
      this.orderReminderDates.push({
        date: subDays(parseISO(this.checkIn), this.addReminderDate),
        daysFromArrival: this.addReminderDate,
      });
      this.$emit('update', this.serialisedDates(this.orderReminderDates));
    },
    serialisedDates(objectsArray) {
      return objectsArray.map((el) => el.date);
    },
  },

};

</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
