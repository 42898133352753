const enumTemplateNames = {
  INITIAL_PAYMENT_REQUEST: 'initialPaymentRequestTemplate',
  REMINDER: 'reminderTemplate',
};
Object.freeze(enumTemplateNames);

const enumOrderStatuses = {
  NEW: 'NEW',
  OPENED: 'OPENED',
  PENDING: 'PENDING',
  PENDING_OPENED: 'PENDING_OPENED',
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  EXPIRED: 'EXPIRED',
};
Object.freeze(enumOrderStatuses);

const enumPaymentStatuses = {
  // Cardlink: https://developer.cardlink.gr/documentation_categories/response-codes/#VPOS-Transaction-Statuses
  AUTHORIZED: 'AUTHORIZED',
  'AUTHORISED-EXPIRED': 'AUTHORISED-EXPIRED',
  CAPTURED: 'CAPTURED',
  COMPLETE: 'COMPLETE',
  OFFERED: 'OFFERED',
  'CAPTURED PARTIALLY': 'CAPTURED PARTIALLY',
  PREPROCESS: 'PREPROCESS',
  INAUTHENTICATION: 'INAUTHENTICATION',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR:',
  REFUSED: 'REFUSED',
  REFUNDED: 'REFUNDED',
  'REFUNDED PARTIALLY': 'REFUNDED PARTIALLY',
  REVERSED: 'REVERSED',
  VOID: 'VOID',
};
Object.freeze(enumPaymentStatuses);

const enumPaymentMethods = {
  VISA: 'VISA',
  MASTERCARD: 'MASTERCARD',
  AMEX: 'AMEX',
  OTHER: 'OTHER',
};
Object.freeze(enumPaymentMethods);

const enumOrderTypes = {
  BOOKING: 'Booking',
  OTHER: 'Other',
};
Object.freeze(enumOrderTypes);

module.exports = {
  enumTemplateNames,
  enumOrderStatuses,
  enumPaymentStatuses,
  enumPaymentMethods,
  enumOrderTypes,
};
