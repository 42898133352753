<template>
  <div>
    <v-form
      ref="form"
      v-model="validForm"
      lazy-validation
    >
      <v-dialog
        v-model="thedialog"
        content-class="dialogs"
        max-width="700"
        @click:outside="closeDialog"
      >
        <v-card>
          <v-card-title>
            Create new order
          </v-card-title>
          <v-divider />

          <!-- <v-card-subtitle>
          Order ID
        </v-card-subtitle> -->
          <v-card-text>
            <v-container mt-4>
              <div class="body-2 font-weight-bold pb-5">
                Customer information
              </div>

              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="firstName"
                    :rules="[rules.required]"
                    label="First name"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="lastName"
                    label="Last name"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="6">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="phone"
                    label="Phone"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-divider />

              <div class="body-2 font-weight-bold mt-4 pb-5">
                Order information
              </div>

              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="orderType"
                    :items="orderTypes"
                    label="Order type"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="6">
                  <CurrencyInput
                    field-reference="totalAmountField"
                    label="Booking Total"
                    @update="totalAmountUpdated($event)"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="bookingReference"
                    label="Booking reference"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col cols="6">
                  <v-select
                    v-model="selectedBookingTermId"
                    :items="orgBookingTerms"
                    label="Booking Terms"
                    item-text="title"
                    item-value="id"
                    return-object
                    outlined
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  v-if="selectedBookingTermId"
                  cols="12"
                >
                  <v-textarea

                    readonly
                    outlined
                    no-resize
                    auto-grow
                    dense
                    label="Terms description"
                    :value="orgBookingTerms.find(el => el.id === selectedBookingTermId).description"
                  />
                </v-col>
              </v-row>

              <div>
                <v-row class="mt-0">
                  <v-col cols="6">
                    <v-menu
                      ref="checkInMenu"
                      v-model="checkInMenu"
                      :nudge-right="40"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="checkIn"
                          label="Check in"
                          prepend-icon="mdi-calendar"
                          readonly
                          :filled="!!checkIn"
                          v-bind="attrs"
                          :outlined="checkInMenu && !checkIn"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="checkInAndOutDatesArray"
                        color="primary"
                        no-title
                        scrollable
                        range
                        :allowed-dates="allowedCheckInDates"
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="checkInMenu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="checkInAndOutDates(checkInAndOutDatesArray)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-menu
                      ref="checkOutMenu"
                      v-model="checkOutMenu"
                      :nudge-right="40"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="checkOut"
                          label="Check Out"
                          prepend-icon="mdi-calendar"
                          readonly
                          :filled="!!checkOut"
                          :outlined="checkInMenu && checkIn && !checkOut"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="checkOutSelectedDate"
                        color="primary"
                        no-title
                        :allowed-dates="allowedCheckOutDates"
                        scrollable
                      >
                        <v-spacer />
                        <v-btn
                          text
                          color="primary"
                          @click="checkOutMenu = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="checkOutDate(checkOutSelectedDate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-divider />

                <div class="body-2 font-weight-bold mt-4 pb-5">
                  Payment details
                </div>
                <v-row>
                  <v-col>
                    <v-row>
                      <v-col>
                        <CurrencyInput
                          :key="amountToChargeKey"
                          field-reference="amountToChargeField"
                          label="Amount to charge"
                          :def-amount="amountToChargeInCents"
                          @update="amountToChargeUpdated($event)"
                        />
                      </v-col>
                      <v-col>
                        <v-checkbox
                          v-if="selectedBookingTermId && policyPercentToCharge "
                          v-model="usePolicyToChargeCheckbox"
                          class="mt-0 mb-0 align-top"
                          :label="`Use term's charge (${policyPercentToCharge}%)`"
                        />
                      </v-col>
                    </v-row>
                  <!-- <v-checkbox
                    v-model="hasInstallments"
                    :disabled="!amountToChargeInCents"
                    class="mt-0 mb-0 align-top"
                    label="Installments"
                  />
                  <v-text-field
                    v-if="hasInstallments"
                    v-model="installments"
                    type="number"
                    onkeypress="return event.charCode >= 50"
                    min="1"
                    label="# of installments"
                    :hint="`Use the same link to charge ${numberToMoney(Math.round(amountToChargeInCents / (installments? installments : 1) ))} each time`"
                    persistent-hint
                    outlined
                    dense
                  /> -->
                  </v-col>
                </v-row>
                <v-divider />
                <div class="body-2 font-weight-bold mt-4 pb-5">
                  Payment link expiration date
                </div>
                <v-row class="">
                  <v-col>
                    <OrderExpiration
                      @update="expirationDateUpdated($event)"
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <div class="body-2 font-weight-bold mt-4 ">
                  Order communication by email
                </div>
                <div v-if="selectedBookingTermId && checkIn">
                  Based on the selected Booking Terms, the Payment link email will be sent on {{ dateLinkWillBeSentByPolicy.toISOString() | IsoToBookingDates }}
                  ({{ selectedPolicy.linkDispatchInDaysBeforeArrival }} days before selected arrival date).

                  You can also add up to 3 additional reminders between that date and the arrival.

                  Reminders will be sent only if the payment is not done.
                </div>
                <div class="caption pb-5 mt-3">
                  Add up to 3 email communications. Simply enter how many days from date of arrival should the reminder be sent to the guest.
                </div>

                <v-row class="">
                  <v-col>
                    <OrderCommunications
                      :key="orderCommsKey"
                      :order-communications="processOrderCommunications()"
                      :check-in="checkIn"
                      @update="updateOrderCommunications($event)"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>

          <v-card-actions class="justify-center pb-4">
            <v-btn
              outlined
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-spacer />
            <v-btn
              :disabled="! validForm"
              color="primary"
              @click="createOrder"
            >
              Create Order
            </v-btn>
            <v-spacer />
            <v-btn
              outlined
              small
              color="error"
              @click="clearform()"
            >
              Clear form
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
  </div>
</template>

<script>
// import OrderOverviewByState from './OrderDialogOverviewByState.vue';

import {
  format, startOfToday, parseISO, sub,
} from 'date-fns';
import { required, counter, email } from '../../helpers/validationRules';

import mixins from '../../helpers/mixins';
import { enumOrderTypes, enumTemplateNames } from '../../helpers/enums';
import CurrencyInput from './CurrencyInput.vue';
import OrderExpiration from './OrderExpiration.vue';
import OrderCommunications from './OrderCommunications.vue';

export default {
  components: {
    CurrencyInput,
    OrderExpiration,
    OrderCommunications,
  },
  mixins: [mixins],
  data() {
    return {
      checkInMenu: false,
      checkOutMenu: false,
      checkInAndOutDatesArray: [],
      checkInSelectedDate: null,
      checkOutSelectedDate: null,
      usePolicyToChargeCheckbox: false,
      hasInstallments: false,
      amountToChargeKey: Math.random(),
      orderCommsKey: Math.random(),
      validForm: true,
      rules: {
        required,
        counter,
        email,
      },
    };
  },
  computed: {
    orderTypes() {
      return Object.keys(enumOrderTypes) || [];
    },
    thedialog: {
      get() {
        return this.$store.getters['newOrder/getNewOrderDialogValue'];
      },
      set(value) {
        this.$store.commit('newOrder/SET_NEW_ORDER_DIALOG_VALUE', value);
      },
    },
    order() {
      return this.$store.getters['newOrder/get'](['order']);
    },

    policyPercentToCharge() {
      return this.selectedPolicy.prepaymentPercent;
    },

    orderType: {
      get() {
        if (!this.$store.getters['newOrder/get'](['order', 'order', 'type'])) {
          this.$store.commit('newOrder/SET', [['order', 'order', 'type'], enumOrderTypes.BOOKING]);
          return enumOrderTypes.BOOKING;
        }
        return this.$store.getters['newOrder/get'](['order', 'order', 'type']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'order', 'type'], value]);
      },
    },
    firstName: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'customer', 'firstName']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'customer', 'firstName'], value]);
      },
    },
    lastName: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'customer', 'lastName']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'customer', 'lastName'], value]);
      },
    },
    email: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'customer', 'email']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'customer', 'email'], value]);
      },
    },
    phone: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'customer', 'phone']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'customer', 'phone'], value]);
      },
    },
    address: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'customer', 'address']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'customer', 'address'], value]);
      },
    },
    country: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'customer', 'countryCode']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'customer', 'countryCode'], value]);
      },
    },
    totalAmountInCents: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'bookingOrder', 'totalAmountInCents']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'totalAmountInCents'], value]);
      },
    },
    amountToChargeInCents: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'order', 'amountToChargeInCents']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'order', 'amountToChargeInCents'], value]);
      },
    },
    installments: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'order', 'installments']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'order', 'installments'], +value]);
      },
    },

    checkIn: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'bookingOrder', 'checkIn']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkIn'], value]);
      },
    },
    checkOut: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'bookingOrder', 'checkOut']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkOut'], value]);
      },
    },
    bookingReference: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'bookingOrder', 'bookingReference']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'bookingReference'], value]);
      },
    },
    orgBookingTerms() {
      return this.$store.getters['newOrder/get'](['bookingTerms']);
    },
    selectedBookingTermId: {
      get() {
        return this.$store.getters['newOrder/get'](['order', 'bookingOrder', 'bookingTermId']);
      },
      set(value) {
        this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'bookingTermId'], value.id]);
      },
    },
    dateLinkWillBeSentByPolicy() {
      const daysFromArrival = this.selectedPolicy.linkDispatchInDaysBeforeArrival;

      const date = sub(parseISO(this.checkIn), { days: daysFromArrival });

      return date;
    },

    selectedPolicy() {
      return this.orgBookingTerms.find((el) => el.id === this.selectedBookingTermId);
    },
    // orderCommsFromPolicy() {
    //   if (this.selectedBookingTermId && this.checkIn) {
    //     // console.log('h');
    //     const dates = this.selectedPolicy.reminderDatesRelativeToArrivalDate.map((el) => ({
    //       date: sub(parseISO(this.checkIn), { days: el }),
    //       daysFromArrival: el,
    //     }));

    //     return dates;
    //   }
    //   return [];
    // },
    policySelectAndCheckin() {
      // eslint-disable-next-line no-sequences
      return this.selectedBookingTermId, this.checkIn, Date.now();
    },

  },
  watch: {
    policySelectAndCheckin() {
      this.$store.commit('newOrder/SET', [['order', 'orderCommunications'], this.processOrderCommunications()]);
      this.orderCommsKey += 1;
    },
    // selectedBookingTermId() {
    //   this.orderCommsKey += 1;
    // },

    usePolicyToChargeCheckbox(value) {
      if (value) {
        // Divide by 100 to turn 50% to 0.5
        const amnt = (this.totalAmountInCents * (this.policyPercentToCharge / 100));
        this.$store.commit('newOrder/SET', [['order', 'order', 'amountToChargeInCents'], amnt]);
        this.amountToChargeKey += 1;
      }
    },
    checkInAndOutDatesArray(newA, oldA) {
      if (newA !== oldA) {
        if (newA.length === 1) {
          this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkIn'], newA[0]]);
          if (this.checkOut && parseISO(newA[0]) > parseISO(this.checkOut)) {
            this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkOut'], null]);
          }
        } else if (newA.length === 2) {
          const sortedArray = newA.sort();
          this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkIn'], sortedArray[0]]);
          this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkOut'], sortedArray[1]]);
        }
      }
    },
    checkOutSelectedDate(oldDate, newDate) {
      if (oldDate !== newDate) {
        this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkOut'], oldDate]);
      }
    },

  },
  mounted() {
    this.$store.dispatch('newOrder/setBookingTerms');
    this.$store.commit('newOrder/SET', [['order', 'organisation', 'slug'], this.$router.currentRoute.params.slug]);
  },
  methods: {
    totalAmountUpdated(amount) {
      this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'totalAmountInCents'], amount]);
    },
    amountToChargeUpdated(amount) {
      this.$store.commit('newOrder/SET', [['order', 'order', 'amountToChargeInCents'], amount]);

      this.usePolicyToChargeCheckbox = null;
    },
    expirationDateUpdated(date) {
      this.$store.commit('newOrder/SET', [['order', 'order', 'expirationDate'], date]);
    },
    updateOrderCommunications(communicationDates) {
      const comms = communicationDates.map((el) => ({
        templateName: enumTemplateNames.REMINDER,
        sendAt: el.toISOString(),
      }));
      this.$store.commit('newOrder/SET', [['order', 'orderCommunications'], comms]);
    },
    closeDialog() {
      this.$store.commit('newOrder/SET_NEW_ORDER_DIALOG_VALUE', false);
      // this.$store.commit('orders/SET_NEW_ORDER_DIALOG_OBJECT', null);
    },
    checkInAndOutDates(datesArray) {
      this.$refs.checkInMenu.save(datesArray);
      const sortedArray = datesArray.sort();

      this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkIn'], sortedArray[0]]);
      this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkOut'], sortedArray[1]]);
    },
    checkOutDate(checkOutDate) {
      this.$refs.checkOutMenu.save(checkOutDate);

      this.$store.commit('newOrder/SET', [['order', 'bookingOrder', 'checkOut'], checkOutDate]);
    },

    allowedCheckInDates(val) {
      const today = startOfToday();
      const todayFormated = format(today, 'yyyy-MM-dd');
      return val >= todayFormated;
    },

    allowedCheckOutDates(val) {
      return val >= this.checkIn;
    },
    createOrder() {
      // console.log(JSON.stringify(this.order, null, 2));
      this.$store.dispatch('newOrder/createOrder');
    },
    clearform() {
      this.$store.commit('newOrder/RESET_ORDER', [['order'], {}]);
      this.$emit('cleared');
      // this.$store.commit('newOrder/SET_NEW_ORDER_DIALOG_VALUE', false);
    },
    processOrderCommunications() {
      if (this.selectedBookingTermId && this.checkIn) {
        // console.log('h');
        const dates = this.selectedPolicy.reminderDatesRelativeToArrivalDate.map((el) => ({
          date: sub(parseISO(this.checkIn), { days: el }),
          daysFromArrival: el,
          templateName: enumTemplateNames.REMINDER,
        }));

        return dates;
      }
      return [];
    },
  },
};
</script>
