import axios from 'axios';
import router from '../router/index';
import { projectAuth } from '../firebase/config';
import { setProp, getProp } from '../helpers/vuexHelpers';

const getDefaultState = () => ({
  newOrderDialog: false,
  bookingTerms: null,
  order: {},
});
const orders = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getNewOrderDialogValue(state) {
      return state.newOrderDialog;
    },
    get: (state) => (props) => getProp(state, props),
    // getNewOrderDialogObject(state) {
    //   return state.newOrderDialogObject;
    // },
  },
  mutations: {
    SET(state, payload) {
      setProp(state, [...payload[0]], payload[1]);
    },
    SET_NEW_ORDER_DIALOG_VALUE(state, payload) {
      state.newOrderDialog = payload;
    },
    SET_NEW_ORDER_DIALOG_OBJECT(state, payload) {
      state.newOrderDialogObject = payload;
    },
    SET_BOOKING_TERMS(state, payload) {
      state.bookingTerms = payload;
    },
    RESET_ORDER(state) {
      Object.assign(state, getDefaultState());

      // for (const prop of Object.getOwnPropertyNames(state.order)) {
      //   delete state.order[prop];
      // }
      // state.order = payload;
    },
  },
  actions: {
    async setBookingTerms({ commit }) {
      // const authUser = rootGetters['auth/user'];
      // const hotelId = getHotelIdFromSlug(authUser, router.currentRoute.params.slug);
      const endpoint = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/bookingterms?organisation=${router.currentRoute.params.slug}`;
      const idToken = await projectAuth.currentUser.getIdToken();

      const data = await axios.get(endpoint, {
        headers: {
          authorization: idToken,
        },
      });
      // console.log(data.data);
      commit('SET_BOOKING_TERMS', data.data);
    },
    async createOrder({ state, dispatch, commit }) {
      // console.log(state.order);
      const endpoint = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/order`;
      const idToken = await projectAuth.currentUser.getIdToken();

      await axios.post(endpoint, state.order, {
        headers: {
          authorization: idToken,
        },
      });

      // Load the order again
      dispatch('orders/setOrders', null, { root: true });

      commit('SET_NEW_ORDER_DIALOG_VALUE', false);
    },

  },
};

export default orders;
