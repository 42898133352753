<template>
  <div>
    <v-container
      v-if="order.bookingOrder"
      class="pa-8"
    >
      <v-dialog
        v-if="dialog"
        v-model="dialog"
      >
        <v-card
          height="calc(100vh - 100px)"
        >
          <v-container
            style="background-color: white"

            class="text-center"
          >
            <!-- <pre>{{ iframe }}</pre> -->
            <iframe
              ref="frame"
              frameBorder
              :src="iframe.src"
              width="500px"
              height="880px"
            />
          </v-container>
        </v-card>
      </v-dialog>
      <v-row v-if="order.bookingOrder.bookingReference">
        <v-col>
          <small class="grey--text">Booking reference: {{ order.bookingOrder.bookingReference }}</small>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>{{ order.organisation.name }}</p>
        </v-col>

        <v-col>
          Logo here
        </v-col>
      </v-row>

      <v-row v-if="order.bookingOrder">
        <v-col>
          <v-sheet
            rounded
            outlined
          >
            <div class="ma-3">
              <p>
                This is a secure payment link for your stay at {{ order.organisation.name }}, from <b>{{ order.bookingOrder.checkIn | IsoToBookingDates }}</b>
                to <b>{{ order.bookingOrder.checkOut | IsoToBookingDates }}</b>
                ({{ checkInCheckOutToNumberOfDays(order.bookingOrder.checkOut,order.bookingOrder.checkIn) }} nights).
              </p>

              <p v-if="order.expirationDate">
                The link is valid until {{ order.expirationDate | genericDateTime }} (property's time zone).
              </p>
            </div>
          </v-sheet>

        <!-- <v-card
          class="mx-auto"
          outlined
          rounded
        >
          <v-card-text>
            <p class="body-1 text-c">
              Order details
            </p>
            <p>
              checkIn: {{ order.bookingOrder.checkIn }}
              <br>
              checkout: {{ order.bookingOrder.checkOut }}
              <br>
              bookingRef: {{ order.bookingOrder.bookingReference }}
            </p>
          </v-card-text>
        </v-card> -->
        </v-col>
      </v-row>

      <p class="text-body-1 mt-6">
        Your details
      </p>
      <v-row class="mb-n10">
        <v-col>
          <v-text-field
            v-model="firstName"
            dense
            label="First name"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="lastName"
            dense
            label="Last name"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="mb-n10">
        <v-col>
          <v-text-field
            v-model="email"
            dense
            label="Email"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="phone"
            dense
            label="Mobile Phone"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="mb-n10">
        <v-col>
          <v-text-field
            v-model="address"
            dense
            label="Address"
            outlined
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="country"
            dense
            label="Country"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="mb-n10">
        <v-col>
          <v-textarea
            readonly
            outlined
            no-resize

            auto-grow
            name="input-7-4"
            label="Terms"
            :value="`${bookingTerm.description}${bookingTerm.description}`"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-sheet
            rounded=""
            outlined
          >
            <div class="ma-3">
              Total amount: {{ numberToMoney(order.bookingOrder.totalAmountInCents) }}
              <br>
              <v-sheet
                class="pa-2"
                color="#edf8ff"
              >
                Now paying: {{ numberToMoney(order.amountToChargeInCents) }}
              </v-sheet>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row class="mt-n2 mb-n8">
        <v-col>
          <v-checkbox v-model="termsCheckbox">
            <template #label>
              <div>
                I agree with the booking terms stated above, and the
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <a
                      target="_blank"
                      href="https://vuetifyjs.com"
                      @click.stop
                      v-on="on"
                    >
                      Privacy Policy.
                    </a>
                  </template>
                  Opens in new window
                </v-tooltip>
              </div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            block
            color="primary"
            @click="handlePayment"
          >
            Pay (secure via Bank)
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-else
    >
      <p>Not a Booking Order</p>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import mixins from '../helpers/mixins';

export default {
  name: 'PublicOrderForm',

  components: {
  },
  mixins: [mixins],
  data() {
    return {
      termsCheckbox: false,
      iframe: {
        // src: 'http://localhost/iframe/',
        src: '',
        loaded: true,
      },
      dialog: false,
    };
  },
  computed: {
    order() {
      return this.$store.getters['publicOrderForm/get'](['order']);
    },

    firstName: {
      get() {
        return this.$store.getters['publicOrderForm/get'](['order', 'customer', 'firstName']);
      },
      set(value) {
        this.$store.commit('publicOrderForm/SET', [['order', 'customer', 'firstName'], value]);
      },
    },
    lastName: {
      get() {
        return this.$store.getters['publicOrderForm/get'](['order', 'customer', 'lastName']);
      },
      set(value) {
        this.$store.commit('publicOrderForm/SET', [['order', 'customer', 'lastName'], value]);
      },
    },
    email: {
      get() {
        return this.$store.getters['publicOrderForm/get'](['order', 'customer', 'email']);
      },
      set(value) {
        this.$store.commit('publicOrderForm/SET', [['order', 'customer', 'email'], value]);
      },
    },
    phone: {
      get() {
        return this.$store.getters['publicOrderForm/get'](['order', 'customer', 'phone']);
      },
      set(value) {
        this.$store.commit('publicOrderForm/SET', [['order', 'customer', 'phone'], value]);
      },
    },
    address: {
      get() {
        return this.$store.getters['publicOrderForm/get'](['order', 'customer', 'address']);
      },
      set(value) {
        this.$store.commit('publicOrderForm/SET', [['order', 'customer', 'address'], value]);
      },
    },
    country: {
      get() {
        return this.$store.getters['publicOrderForm/get'](['order', 'customer', 'countryCode']);
      },
      set(value) {
        this.$store.commit('publicOrderForm/SET', [['order', 'customer', 'countryCode'], value]);
      },
    },
    bookingTerm() {
      return this.$store.getters['publicOrderForm/get'](['order', 'bookingOrder', 'bookingTerm']);
    },
  },
  created() {
    window.addEventListener('message', (event) => {
      // console.log(event);
      try {
        const response = event.data ? JSON.parse(event.data) : null;
        console.log(response);
        if (response.completed) {
          // console.log(`Hello from ${JSON.stringify(event.data)}`);
          this.dialog = false;
          this.$router.push({ name: 'PublicOrderFormResult', params: { uuid: this.$router.currentRoute.params.uuid } });
        }
      } catch (error) {
        console.log('postmessage error');
        console.log(error);
      }
    });
  },
  destroyed() {
    window.removeEventListener('message', (event) => {
      console.log(`destroying ${JSON.stringify(event)}`);
      this.dialog = false;
    });
  },
  mounted() {
    this.$store.dispatch('publicOrderForm/setOrder');
  },
  methods: {
    async handlePayment() {
      const req = `https://8302-116-88-174-6.ap.ngrok.io/payments/${this.$router.currentRoute.params.uuid}/links`;
      const data = await axios.get(req);
      console.log(data.data);
      this.iframe.src = data.data;
      this.dialog = true;
    },
  },
};
</script>
