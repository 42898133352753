<template>
  <div>
    <v-row justify="space-between">
      <v-col cols="6">
        <v-btn-toggle
          v-model="presetDateSelected"
          tile
          color="primary"
        >
          <v-btn
            outlined
            class="mr-2  rounded-lg"
            text
            value="1h"
          >
            1h
          </v-btn>

          <v-btn
            outlined
            class="mr-2 btn-group-left-border rounded-lg"
            value="1d"
          >
            1d
          </v-btn>

          <v-btn
            outlined
            class="mr-2 btn-group-left-border rounded-lg "
            value="3d"
          >
            3d
          </v-btn>

          <v-btn
            outlined
            class="mr-2 btn-group-left-border  rounded-lg"
            value="7d"
          >
            7d
          </v-btn>

          <v-btn
            outlined
            class="mr-2 btn-group-left-border  rounded-lg"
            value="open"
          >
            Open
          </v-btn>
        </v-btn-toggle>
      </v-col>

      <v-col cols="5">
        <v-menu
          ref="expDateMenu"
          v-model="expDateMenu"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="customDateSelected"
              label="Custom Date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="customDateSelected"
            :return-value.sync="customDateSelected"
            color="primary"
            no-title
            scrollable
            :allowed-dates="allowedDates"
          />
        </v-menu>
      </v-col>
    </v-row>
    <v-row
      v-if="finalDate"
      class="mt-0"
    >
      <v-col>
        <span v-if="finalDate === 'open'">
          The link does not expire.
        </span>
        <span v-else>
          Link will expire on {{ finalDate | genericDateTime }}.

        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  format, startOfToday, addHours, addDays, endOfDay, parseISO,
} from 'date-fns';

export default {
  props: {
    fieldReference: {
      type: String,
      default: 'fieldRef',
    },
    label: {
      type: String,
      default: 'fieldLabel',
    },
    defAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      presetDateSelected: null,
      expDateMenu: false,
      customDateSelected: null,
      finalDate: null,

    };
  },
  watch: {
    customDateSelected(val) {
      if (!val) return;
      this.presetDateSelected = null;
      this.finalDate = endOfDay(parseISO(val)).toISOString();
      this.$emit('update', this.finalDate);
    },
    presetDateSelected(val) {
      if (!val) return;
      this.customDateSelected = null;
      if (val === 'open') {
        this.finalDate = 'open';
        this.$emit('update', null);
        return;
      }

      let theDate = null;
      const now = new Date();
      switch (val) {
        case '1h':
          theDate = addHours(now, 1);
          break;
        case '1d':
          theDate = addDays(now, 1);
          break;
        case '3d':
          theDate = addDays(now, 3);
          break;
        case '7d':
          theDate = addDays(now, 7);
          break;
        case 'open':
          theDate = addDays(now, 7);
          break;

        default:
          break;
      }
      this.finalDate = theDate.toISOString();
      this.$emit('update', this.finalDate);
    },
  },
  methods: {
    allowedDates(val) {
      const today = startOfToday();
      const todayFormated = format(today, 'yyyy-MM-dd');
      return val >= todayFormated;
    },
  },

};

</script>

<style>

</style>
