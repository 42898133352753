import {
  format, parseISO, differenceInDays, startOfDay,
} from 'date-fns';
import Dinero from 'dinero.js';
import { enumOrderStatuses, enumPaymentStatuses } from './enums';

function getOrderStatusColor(status) {
  // console.log(status);
  switch (status) {
    case enumOrderStatuses.NEW:
      return '#e8f7ff';
    case enumOrderStatuses.OPENED:
      return '#e3e7ff';
    case enumOrderStatuses.PENDING_OPENED:
      return '#e3e7ff';
    case enumOrderStatuses.ACCEPTED:
      return '#e3ffd9';
    case enumOrderStatuses.CANCELED:
      return '#ffd4d4';
    case enumOrderStatuses.EXPIRED:
      return '#f5f5f5';
    default:
      return '#fff';
  }
}

function getPaymentsStatusColor(status) {
  switch (status) {
    case enumPaymentStatuses.AUTHORIZED:
      return '#e3ffd9';
    case enumPaymentStatuses.CAPTURED:
      return '#e3ffd9';
    case enumPaymentStatuses.OFFERED:
      return '#E3DEFF';
    case enumPaymentStatuses.REFUNDED:
      return '#FFF0D4';
    case enumPaymentStatuses.REFUSED:
      return '#E3DBD1';
    case enumPaymentStatuses.ERROR:
      return '#ffd4d4';
    default:
      return '#fff';
  }
}

function dateFormatDataTables(date) {
  if (!date) return null;
  return format(parseISO(date), 'dd/MM/YYY, HH:mm');
}

function dateFormatCheckin(date) {
  return format(parseISO(date), 'dd/MM/YYY');
}

function concatFirstLastName(firstName, lastName) {
  return `${firstName} ${lastName}`;
}

function checkInCheckOutToNumberOfDays(checkout, checkin) {
  return differenceInDays(startOfDay(parseISO(checkout)), startOfDay(parseISO(checkin)));
}

function numberToMoney(amount, currency = 'EUR') {
  if (amount !== parseInt(amount, 10)) return null;
  const price = Dinero({ amount, currency })
  // .setLocale('el-GR')
    .toFormat();
  // console.log(price);
  return price;
}

export default {
  methods: {
    getOrderStatusColor,
    dateFormatDataTables,
    concatFirstLastName,
    numberToMoney,
    dateFormatCheckin,
    getPaymentsStatusColor,
    checkInCheckOutToNumberOfDays,
  },
};
