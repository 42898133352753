import axios from 'axios';
import { projectAuth } from '../firebase/config';
import router from '../router/index';

const auth = {
  namespaced: true,
  state: {
    // Firebase auth
    email: null,
    userId: null,
    role: null,

    // Database
    organisations: null,
    firstName: '',
    lastName: '',
  },
  mutations: {
    SET_FIREBASE_AUTH_USER_DATA(state, payload) {
      state.email = payload.email;
      state.userId = payload.userId;
    },
    SET_FIREBASE_AUTH_USER_CLAIMS(state, payload) {
      state.role = payload;
    },
    SET_USER_DATA(state, payload) {
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.organisations = payload.organisations;
    },
  },
  getters: {
    user(state) {
      return {
        email: state.email,
        userId: state.userId,
        role: state.role,
        organisations: state.organisations,
        firstName: state.firstName,
        lastName: state.lastName,
      };
    },
  },
  actions: {
    async login({ dispatch }, payload) {
      // sign user in
      const { user } = await projectAuth.signInWithEmailAndPassword(payload.email, payload.password);

      dispatch('fetchAuthUserData', user);
      router.push({ name: 'SelectProperty' });
    },
    async fetchAuthUserData({ commit }, user) {
      commit('SET_FIREBASE_AUTH_USER_DATA', { email: user.email, userId: user.uid });

      // Custom claims for role
      const { claims } = await user.getIdTokenResult();
      let role = null;
      if (claims.AouAdmin) role = 'AouAdmin';
      if (claims.hotel_user) role = 'hotel_user';
      commit('SET_FIREBASE_AUTH_USER_CLAIMS', role);

      // Get user data
      // const mockDataRelationships = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/user?uuid=${user.uid}`;

      const idToken = await projectAuth.currentUser.getIdToken();
      const req = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/user?uuid=${user.uid}`;
      const data = await axios.get(req, {
        headers: {
          authorization: idToken,
        },
      });
      // console.log('data.data');
      // console.log(data.data);
      // const data = await axios.get(endpoint);
      commit('SET_USER_DATA', data.data);
    },
    async logout({ commit }) {
      // sign user out
      await projectAuth.signOut();

      commit('SET_FIREBASE_AUTH_USER_DATA', {
        email: null,
        userId: null,
        role: null,
        organisations: null,
        firstName: null,
        lastName: null,
      });

      router.push({ name: 'Login' });
    },
  },
};
export default auth;
