<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list-item>
        <!-- <v-list-item-avatar> -->
        <!-- <v-img src="https://picsum.photos/seed/picsum/50/50" /> -->
        <!-- </v-list-item-avatar> -->
        <v-list-item-content>
          <v-list-item-title class="title">
            <!-- Pay Rangers -->
            <v-img src="@/assets/payrangers3.png" />
          </v-list-item-title>
          <!-- <v-list-item-subtitle> For Canvas Suites </v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list
        dense
        nav
      >
        <template
          v-for="(item, index) in items"
        >
          <v-divider
            v-if="item.divider"
            :key="index"
            class="mt-5 pb-5"
          />
          <v-list-item
            :key="item.title"
            link
            :to="item.to"
            exact
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
          @click="logout()"
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      flat
      color="transparent"
      app
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" />

      <v-spacer />

      <div>{{ user.email }} | {{ user.role }} </div>

      <!-- <v-toolbar-title>Application</v-toolbar-title> -->
    </v-app-bar>

    <v-container>
      <v-fade-transition
        mode="out-in"
        duration="200"
      >
        <slot />
      </v-fade-transition>
    </v-container>
  </div>
</template>

<script>

export default {
  data: () => ({
    drawer: false,
    items: [
      { title: 'Home', to: { name: 'Home' }, icon: 'mdi-home' },
      { title: 'Orders', to: { name: 'Orders' }, icon: 'mdi-bookmark-outline' },
      { title: 'Payments', to: { name: 'Payments' }, icon: 'mdi-credit-card-outline' },
      { title: 'Customers', to: { name: 'Customers' }, icon: 'mdi-account-multiple' },
      { title: 'Notes', to: { name: 'Notes' }, icon: 'mdi-message-bulleted' },
      { title: 'Reports', to: { name: 'Reports' }, icon: 'mdi-table-multiple' },
      {
        title: 'Settings', to: { name: 'Settings' }, icon: 'mdi-cog', divider: true,
      },
      { title: 'Profile', to: { name: 'Profile' }, icon: 'mdi-account' },
    ],
    right: null,
  }),
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  mounted() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.drawer = true;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
