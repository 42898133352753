<template>
  <div>
    Profile
    <br>
    <v-btn @click="testBackendAuth">
      click
    </v-btn>
    <pre>{{ user }}</pre>
  </div>
</template>

<script>
import axios from 'axios';
import { projectAuth } from '../firebase/config';

export default {
  name: 'Orders',

  components: {
  },
  computed: {
    user() {
      // const d = this.$store.getters['auth/user'];
      // console.log(d);
      // console.log(d.hotels);
      // return 1;
      return this.$store.getters['auth/user'];
    },
    // userData() {
    //   return this.$store.getters['auth/user'];
    // },
  },
  methods: {
    async testBackendAuth() {
      const idToken = await projectAuth.currentUser.getIdToken();
      const req = `http://localhost:6005/orders?organisation=${this.$router.currentRoute.params.slug}`;
      const data = await axios.get(req, {
        headers: {
          authorization: idToken,
        },
      });
      console.log(data.data);
    },
  },
};
</script>
