<template>
  <div>
    <v-card
      elevation="1"
      class="pb-4 pa-4"
    >
      <v-card-title class="justify-center ">
        <div class="text-h4">
          Select property
        </div>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <template
          v-for="(organisation, index) in userData.organisations"
        >
          <v-list-item
            :key="index"
            class="text-left pa-4"
            @click="selected(organisation.slug)"
          >
            <v-list-item-avatar>
              <v-icon>
                mdi-menu-right
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ organisation.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'Orders',

  components: {
  },
  computed: {
    userData() {
      return this.$store.getters['auth/user'];
    },
  },
  mounted() {

  },
  methods: {
    selected(slug) {
      this.$router.push({ name: 'Orders', params: { slug } });
    },
  },
};
</script>
