<template>
  <div>
    <v-container
      class="pa-8"
    >
      <v-row v-if="order">
        <v-col>
          <small class="grey--text">Booking reference: {{ order.bookingOrder.bookingReference }}</small>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>{{ order.organisation.name }}</p>
        </v-col>

        <v-col>
          Logo here
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet
            rounded
            outlined
          >
            <div class="ma-3">
              <v-row>
                <v-col cols="6">
                  <p>
                    Payment of {{ numberToMoney(order.amountToChargeInCents) }} was completed
                  </p>
                </v-col>
                <v-spacer />
                <v-col>
                  <v-chip
                    :color="getPaymentsStatusColor(lastPayment.status)"
                  >
                    {{ lastPayment.status }}
                  </v-chip>
                </v-col>
              </v-row>

              <p>
                Blah blah
              </p>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <!-- <p>{{ id }}</p>

    <pre>
      {{ order }}
    </pre> -->
  </div>
</template>

<script>
import mixins from '../helpers/mixins';

export default {

  name: 'PublicOrderForm',

  components: {
  },
  mixins: [mixins],
  computed: {
    lastPayment() {
      return this.order.payments[0];
    },
    order() {
      return this.$store.getters['publicOrderForm/get'](['order']);
    },
    id() {
      return this.$route.params.uuid;
    },
  },
  mounted() {
    this.$store.dispatch('publicOrderForm/setOrder');
  },
};
</script>
