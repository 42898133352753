import axios from 'axios';
import router from '../router/index';
import { projectAuth } from '../firebase/config';
// import { getHotelIdFromSlug } from '../helpers/helpers';

const orders = {
  namespaced: true,
  state: {
    orders: null,
    orderDialog: false,
    orderDialogObject: null,
  },
  getters: {
    getOrders(state) {
      return state.orders;
    },
    getOrderById: (state) => (id) => {
      console.log(`getOrderById: ${id}`);
      if (state.orders) {
        return state.orders.find((order) => order.id === id);
      }
      return null;
    },
    getOrderDialogValue(state) {
      return state.orderDialog;
    },
    getOrderDialogObject(state) {
      return state.orderDialogObject;
    },
  },
  mutations: {
    SET_ORDERS(state, payload) {
      state.orders = payload;
    },
    SET_ORDER_DIALOG_VALUE(state, payload) {
      state.orderDialog = payload;
    },
    SET_ORDER_DIALOG_OBJECT(state, payload) {
      state.orderDialogObject = payload;
    },
  },
  actions: {
    async setOrders({ commit }) {
      // const authUser = rootGetters['auth/user'];
      // const hotelId = getHotelIdFromSlug(authUser, router.currentRoute.params.slug);
      const endpoint = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/orders?organisation=${router.currentRoute.params.slug}`;
      const idToken = await projectAuth.currentUser.getIdToken();

      const data = await axios.get(endpoint, {
        headers: {
          authorization: idToken,
        },
      });
      // console.log(data.data);
      commit('SET_ORDERS', data.data);
    },
  },
};

export default orders;
