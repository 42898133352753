import axios from 'axios';
import router from '../router/index';
// import { getHotelIdFromSlug } from '../helpers/helpers';
import { projectAuth } from '../firebase/config';

const orders = {
  namespaced: true,
  state: {
    customers: null,
  },
  getters: {
    getCustomers(state) {
      return state.customers;
    },
  },
  mutations: {
    SET_CUSTOMERS(state, payload) {
      state.customers = payload;
    },
  },
  actions: {
    async setCustomers({ commit }) {
      const endpoint = `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/customers?organisation=${router.currentRoute.params.slug}`;
      const idToken = await projectAuth.currentUser.getIdToken();

      const data = await axios.get(endpoint, {
        headers: {
          authorization: idToken,
        },
      });

      commit('SET_CUSTOMERS', data.data);
    },
  },
};

export default orders;
