// See https://jasonwatmore.com/post/2018/09/10/vuejs-set-get-delete-reactive-nested-object-properties
import Vue from 'vue';

function setProp(obj, props, value) {
  const prop = props.shift();
  if (!obj[prop]) {
    Vue.set(obj, prop, {});
  }
  if (!props.length) {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      obj[prop] = { ...obj[prop], ...value };
    } else {
      obj[prop] = value;
    }
    return;
  }
  setProp(obj[prop], props, value);
}

function getProp(obj, props) {
  const prop = props.shift();
  if (!obj[prop] || !props.length) {
    return obj[prop];
  }
  return getProp(obj[prop], props);
}

export { setProp, getProp };
