<template>
  <div>
    <v-card
      elevation="1"
      class="pb-4 pa-4"
    >
      <v-card-title class="justify-center ">
        <div class="aoufont text-h5">
          Pay Rangers
        </div>
      </v-card-title>

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="login"
      >
        <v-card-text>
          <v-text-field
            v-model="email"
            outlined
            :rules="[rules.required, rules.email]"
            placeholder="You email"
            validate-on-blur
          />
          <v-text-field
            v-model="password"
            outlined
            :rules="[rules.required]"
            type="password"
            placeholder="Password"
            :error-messages="errorMessage"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            type="submit"
            depressed
            large
            block
            class="aoufont"
          >
            Login
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { required, counter, email } from '../helpers/validationRules';

export default {
  data() {
    return {
      valid: true,
      email: '',
      password: '',
      errorMessage: null,
      rules: {
        required,
        counter,
        email,
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate();
    },
    login() {
      if (this.$refs.form.validate()) {
        try {
          this.$store.dispatch('auth/login', {
            email: this.email,
            password: this.password,
          });
        } catch (error) {
          console.log(error);
          this.errorMessage = error.message;
        }
      }
    },
  },
};
</script>

<style>

</style>
