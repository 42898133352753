import Vue from 'vue';
import { format, parseISO } from 'date-fns';

Vue.filter('uuidLast8', (uuid) => {
  if (!uuid) {
    return '';
  }
  return uuid.slice(uuid.length - 8);
});

Vue.filter('IsoToBookingDates', (isostring) => {
  if (!isostring) {
    return '';
  }
  return format(parseISO(isostring), 'dd/MM/yyyy');
});

Vue.filter('genericDateTime', (isostring) => {
  if (!isostring) {
    return '';
  }
  return format(parseISO(isostring), 'dd/MM/yyyy, HH:mm');
});
