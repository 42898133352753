import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBnkPYEbzkheuA8v9yBF0wy33kTu8LEyKY',
  authDomain: 'aoultd.firebaseapp.com',
  projectId: 'aoultd',
  storageBucket: 'aoultd.appspot.com',
  messagingSenderId: '93496480435',
  appId: '1:93496480435:web:36b964628e23de8aed16c6',
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

// timestamp
// const timestamp = firebase.FieldValue.serverTimestamp;

export { projectAuth, projectFirestore };
