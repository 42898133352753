var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('OrderDialog'),_c('NewOrderDialog',{key:_vm.newOrderKey,on:{"cleared":function($event){return _vm.clearedNewOrderDialog()}}}),_c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('h1',[_vm._v("Orders")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.newOrderDialog.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" New Order ")],1)],1)],1),(_vm.orders)?_c('div',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.orders,"search":_vm.search},on:{"click:row":_vm.orderDialog},scopedSlots:_vm._u([{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("uuidLast8")(item.uuid))+" ")]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatDataTables(item.dateCreated))+" ")]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.concatFirstLastName(item.customer.firstName, item.customer.lastName))+" ")]}},{key:"item.amountToChargeInCents",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberToMoney(item.amountToChargeInCents))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getOrderStatusColor(item.status),"small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBookingType(item.type))+" ")]}}],null,true)})],1)],1):_c('div',[_vm._v(" LOADING... ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }