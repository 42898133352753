<template>
  <div>
    <v-card elevation="1">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        />
      </v-card-title>

      <v-data-table
        v-if="customers"
        :items="customers"
        :headers="headers"
        :search="search"
      >
        <template #[`item.customer`]="{ item }">
          {{ concatFirstLastName(item.firstName, item.lastName) }}
        </template>
        <template
          #[`item.country`]="{ item }"
        >
          <div
            v-if="item.country"
          >
            <v-img
              style="display:inline-block; vertical-align: top"
              width="20"
              :src="getFlag(item.country.alpha2)"
            />
            <span style="">  {{ item.country ? item.country.name : '' }} </span>
          </div>
        </template>
        <!-- <template #[`item.email`]="
            {
            item
            }"
          >
            {{ item.email }}
          </v-img></span></v-img>
        </template> -->
      <!-- <template #[`item.paymentDate`]="{ item }">
            {{ dateFormatDataTables(item.paymentDate) }}
          </template> -->
      <!-- <template #[`item.paymentMethod`]="{ item }">
            {{ getCCicon(item.paymentMethod) }}
            <v-img
              v-if="item.paymentMethod != 'other'"
              width="30"
              :src="
                require(`@/assets/creditcard-icons/${item.paymentMethod}.svg`)
              "
            />
          </template> -->
      </v-data-table>
    </v-card>
    <!-- {{ customers }} -->
  </div>
</template>

<script>
import mixins from '../helpers/mixins';

export default {
  name: 'Customers',

  components: {
  },
  mixins: [mixins],
  data() {
    return {
      search: '',
      headers: [
        { text: 'Customer', value: 'customer' },
        { text: 'Email', value: 'email' },
        { text: 'Phone', value: 'phone' },
        { text: 'Country', value: 'country' },
        { text: 'firstName', value: 'firstName', align: ' d-none' }, // hides but keeps filterable
        { text: 'lastName', value: 'lastName', align: ' d-none' },
      ],
    };
  },
  computed: {
    customers() {
      return this.$store.getters['customers/getCustomers'];
    },
  },
  mounted() {
    this.$store.dispatch('customers/setCustomers');
  },
  methods: {
    getFlag(alpha2) {
      return `${process.env.VUE_APP_RESOURCES_API_BASE_URL}/flags24x24/${alpha2}.png`;
    },
  },
};
</script>
