<template>
  <div>
    <v-dialog
      v-if="order"
      v-model="thedialog"
      content-class="dialogs"
      max-width="700"
      @click:outside="closeDialog"
    >
      <v-card>
        <v-card-title>
          Customer {{ concatFirstLastName(order.customer.firstName, order.customer.lastName) }}
        </v-card-title>
        <v-card-subtitle>
          Order ID {{ order.uuid | uuidLast8 }}
          &nbsp;
          <a href="#">Copy link</a>
          &nbsp;
          <a
            href="#"
            @click="viewPublicOrder()"
          >View </a>
        </v-card-subtitle>
        <v-card-text>
          <v-divider />
          <OrderOverviewByState
            :order="order"
          />
          <v-divider />

          <!-- Customer INFO -->
          <v-container mt-4>
            <div class="body-2 font-weight-bold pb-5">
              Customer information
            </div>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="order.customer.firstName"
                  label="First name"
                  outlined
                  readonly
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="order.customer.firstName"
                  label="Last name"
                  outlined
                  readonly
                  dense
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="6">
                <v-text-field
                  :value="order.customer.email"
                  label="Email"
                  outlined
                  readonly
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="order.customer.phone"
                  label="Phone"
                  outlined
                  readonly
                  dense
                />
              </v-col>
            </v-row>

            <div class="body-2 font-weight-bold mt-4 pb-5">
              Order information
            </div>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="numberToMoney(order.amountToChargeInCents)"
                  label="Charge"
                  outlined
                  readonly
                  dense
                />
              </v-col>
            </v-row>

            <div v-if="order.bookingOrder">
              <div class="body-2 font-weight-bold mt-4 pb-5">
                Booking information
              </div>
              <v-row class="mt-0">
                <v-col cols="6">
                  <v-text-field
                    :value="numberToMoney(order.bookingOrder.totalAmountInCents)"
                    label="Booking total"
                    outlined
                    readonly
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :value="order.bookingOrder.bookingReference"
                    label="Booking reference"
                    outlined
                    readonly
                    dense
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="6">
                  <v-text-field
                    :value="dateFormatCheckin(order.bookingOrder.checkIn)"
                    label="Check in"
                    outlined
                    readonly
                    dense
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    :value="dateFormatCheckin(order.bookingOrder.checkOut)"
                    label="Check out"
                    outlined
                    readonly
                    dense
                  />
                </v-col>
              </v-row>
              <div class="body-2 font-weight-bold mt-4 pb-5">
                Terms: {{ order.bookingOrder.bookingTerm.title }}
              </div>
              <v-textarea
                name="terms"
                outlined
                readonly
                dense
                auto-grow
                :value="order.bookingOrder.bookingTerm.description"
              />
            </div>
          </v-container>
        </v-card-text>

        <v-card-actions class="justify-center pb-4">
          <v-btn
            outlined
            @click="closeDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrderOverviewByState from './OrderDialogOverviewByState.vue';
import mixins from '../../helpers/mixins';

export default {
  components: {
    OrderOverviewByState,
  },
  mixins: [mixins],

  computed: {
    thedialog: {
      get() {
        return this.$store.getters['orders/getOrderDialogValue'];
      },
      set(value) {
        this.$store.commit('orders/SET_ORDER_DIALOG_VALUE', value);
      },
    },
    order() {
      return this.$store.getters['orders/getOrderDialogObject'];
      // return this.$store.getters['orders/getOrderById'](this.id);
    },
  },
  methods: {
    closeDialog() {
      this.$store.commit('orders/SET_ORDER_DIALOG_VALUE', false);
      this.$store.commit('orders/SET_ORDER_DIALOG_OBJECT', null);
    },
    viewPublicOrder() {
      const routeData = this.$router.resolve({ name: 'PublicOrderForm', params: { uuid: this.order.uuid }, query: { p: 'true' } });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>
