function required(value) { return !!value || 'Required.'; }
function counter(value) { return value.length <= 20 || 'Max 20 characters'; }
function email(value) {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(value) || 'Invalid e-mail.';
}

module.exports = {
  required,
  counter,
  email,
};
